.__react_component_tooltip.type-warning {
    color: #fff;
    background-color: #fafafa;
    opacity: 1;
}


.__react_component_tooltip.type-warning.place-left:after{
    border-left-color: #fafafa;
}

.__react_component_tooltip.type-warning.place-top:after{
    border-top-color: #fafafa;
}
.__react_component_tooltip.type-warning.place-bottom:after{
    border-bottom-color: #fafafa;
}
.__react_component_tooltip.type-warning.place-right:after{
    border-right-color: #fafafa;
}

.__react_component_tooltip.place-top {
    margin-top: 0;
}