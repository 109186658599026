.ReactTable .rt-thead .rt-th.-cursor-pointer, .ReactTable .rt-thead .rt-td.-cursor-pointer {
    cursor: pointer;
    text-align: left;
}



.ReactTable .rt-tbody {
    max-height: 500px;
    overflow-x: hidden;
    border-bottom: 1px solid #dedede;
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
}

.ReactTable .rt-thead.-headerGroups{
    background: #f2f3f5;
    border-top: 1px solid #3e3e3e;
    border-left: 1px solid #dedede;
    border-right: 1px solid #dedede;
}

.account-table .ReactTable .rt-thead{
    background: #f2f3f5;
    border-top: 1px solid #3e3e3e;
}

.ReactTable .rt-thead.-header .rt-th div{
    justify-content: center;
    text-align: center;
    font-size: 16px;
}
.ReactTable .rt-thead.-header .rt-th.-cursor-pointer div{
    justify-content: left;
    text-align: left;
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc{
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer div{
    background: url(img/arow-disable.png) no-repeat right center;
   
}
.ReactTable .rt-thead .rt-th.-sort-asc div{
    background: url(img/arow-asc.png) no-repeat right center;
   
}
.ReactTable .rt-thead .rt-th.-sort-desc div{
    background: url(img/arow-desc.png) no-repeat right center;
   
}

.ReactTable .rt-thead .rt-th div {
    display: inline-flex;
    align-items: center;
    height: 100%;
    width: 100%;
}
.ReactTable .rt-thead.-header{
    box-shadow: none;
    border: 1px solid #dedede;
}
/* .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
    border-right: 1px solid #dedede;
}
.ReactTable .rt-tbody .rt-td {
    border-right: 2px solid #dedede!important;
    border-bottom: 2px solid #dedede;
} */
.ReactTable .rt-tbody .rt-td {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    white-space: pre-line;
    word-break: break-all;
}

.ReactTable .rt-tbody .rt-td button{
    border: none;
    background: transparent;
    color: #062a59;
    text-decoration: underline;
    font-size: 16px;

}

.ReactTable .rt-tbody .rt-td:last-child{
    border-right: none!important;
}


.ReactTable .rt-thead .rt-th{
    color: #020305;
    text-align: left;
    padding: 10px;
}

.ReactTable .rt-thead .rt-th{
    font-size: 16px;
    white-space: pre-line;
    word-wrap: break-word;
}

.ReactTable .rt-tbody .rt-td a{
    color: #062a59;
    font-size: 16px;
    text-decoration: underline;
}

.ReactTable .rt-tbody .rt-td {
    border-right: 1px solid rgba(0,0,0,0.02);
    text-align: center;
    padding: 10px;
}

.date-color{
    color: #197220;
}


.fixed-footer .ReactTable .rt-tfoot .rt-td{
    border: none;
}

.fixed-footer .ReactTable .rt-tfoot{
    height: 53px;
    background: #08478a;
    padding: 0;
    margin: 0;
}

.fixed-footer .ReactTable .rt-tfoot::after{
    content: "";
    height: 18px;
    display: block;
    background: #031d40;
    border-top: 1px solid #d9dbd8;
    padding: 0;
    margin: 0;
}

.ReactTable{
    border: none;
}

.cost-table  .ReactTable{
    margin-bottom: 10px;
}

.cost-table .ReactTable .rt-thead .rt-tr {
    background: #f2f3f5;
}

.cost-table .ReactTable .rt-tfoot{
    box-shadow: none;
    background: #f2f3f5;
    border: 1px solid #dedede;
    margin-top: 10px;
}

.ReactTable .rt-tfoot .rt-td span{
    font-size: 16px;
}

.ReactTable .rt-tfoot .rt-td {
    border-right: 1px solid #dedede;
}

.ReactTable .rt-tfoot .rt-td.noborder {
    border-right: none;
}

.ReactTable .rt-tbody::-webkit-scrollbar {
    width: 20px;
}
 
.ReactTable .rt-tbody::-webkit-scrollbar-thumb {
    background: #062a59 url(img/scroll.png) no-repeat center center;
}
.ReactTable .rt-tbody::-webkit-scrollbar-track {
    background-color: #cccccc;
}
 
.ReactTable .rt-tbody::-webkit-scrollbar-button {
    height: 0;
}

